import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select, { SingleValue } from 'react-select';
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import moment from 'moment-timezone';
import { KTSVG } from '../../../../_metronic/helpers';
import { useAuth } from '../../auth';
import Backdrop from '@mui/material/Backdrop';
import swal from 'sweetalert';
import {
    EventApi,
    DateSelectArg,
    EventClickArg,
    EventContentArg,
    formatDate,
} from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
//import { SimpleTreeView, CustomTreeItem } from './TreeViewComponents';
//@mui/icons-material/ExpandMore
import ExpandIcon from '@mui/material/SvgIcon/';
import Remove from '@mui/icons-material/Remove';
import EndIcon from '@mui/icons-material/MoreHoriz';

import CircularProgress from '@mui/material/CircularProgress';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import "../common/style.css";
import AddTask from "./AddTask";
import DataTable from './DataTable';


// API endpoints
const getUserList = `/users/getUsersList`;
const getPracticeList = `/api/practice/getPracticeListById`;
const getColumnsByUser = `/api/Claims/getColumnsByUser`;
const getPmList = `/api/pmsystem/getPmList`;
const GetTaskList = `/api/ProductionTrack/GetTaskList`;
const ProdCountByRm = `api/ProductionTrack/ProdCountByRm`
const GerUsersProduction = `/api/ProductionTrack/GerUsersProduction`;
const TaskApproveByUser = `api/ProductionTrack/TaskApproveByUser`
const GerUsersProductionByUserid = `api/ProductionTrack/GerUsersProductionByUserid`
const SaveProduction = `api/ProductionTrack/SaveProduction`;
const DownlaodExcelAnalystWiseProduction = `api/AnalystProductivity/DownlaodExcelAnalystWiseProduction`
const ConsolidatedProductivity = `api/ConsolidatedProductivity`

let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

interface Event {
    id?: string,
    title?: string;
    start?: string;
    end?: string;
}
interface WorkingTask {
    _id: string;
    PmId: string;
    PracticeId: string;
    TaskId: string;
    WorkingHours: number;
    Target: string;
    TargetUnit: string;
    ProcessName: string;
    ProofNeed: string;
}

interface DataItem {
    _id: string[];
    userName: string;
    RoleName: string;
    WorkingStatus: string;
    PracticeName: string;
    WorkingDate: string;
    UserId: string;
    TotalCount: number;
    TotalEfficiency: number;
    OthersProduction: number;
    Count: number;
    PCA: number;
    Efficiency: number;
    PCAEfficiency: number;
    WorkingTaskList: WorkingTask[];
}

interface TableProps {
    data: DataItem[];
}
interface ProdValues { FileNameTextError: String, FileName: String, WorkingHoursTextError: String, PCAEfficiency: String, Efficiency: String, PCA: String, Count: String, Payments: String, ProofNeed: String, label: String, value: String, ProcessName: String, WorkingHours: number; TargetUnit: string; Target: number }
interface FormValues {
    FromDate: string;
    WorkingStatus: string;
    SelectedTaskList: any;
}
function bytesForHuman(bytes: any) {
    let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

    let i = 0

    for (i; bytes > 1024; i++) {
        bytes /= 1024;
    }

    return bytes.toFixed(1) + ' ' + units[i]
}
const ValidateProduction: React.FC = () => {
    const today = new Date();
    const maxDate = today.toISOString().split('T')[0];
    const minDate = new Date(today.setDate(today.getDate() - 3)).toISOString().split('T')[0];
    const { currentUser }: any = useAuth()
    const [Files, setFiles] = useState<any[]>([]);
    const [ProviderEditData, setProviderEditData] = useState<any[]>([]);
    const [SelectedPmSystem, setSelectedPmSystem] = useState<SingleValue<{ label: string; value: string }>>({ label: 'Select', value: '' });
    const [SelectedPractice, setSelectedPractice] = useState<SingleValue<{ label: string; value: string }>>({ label: 'Select', value: '' });
    const [SelectedTaskList, setSelectedTaskList] = useState<any[]>([]);
    const [PracticeListData, setPracticeListData] = useState<any[]>([]);
    const [UsersProductionData, setUsersProductionData] = useState<any[]>([]);
    const [UsersPracticeProduction, setUsersPracticeProduction] = useState<any>({ OthersProduction: 0, TotalCount: 0, TotalEfficiency: 0, FinalPCACount: 0, Count: 0, PCA: 0, Efficiency: 0, PCAEfficiency: 0, FilePath: "", AttachmentName: "" });
    const [TaskListData, setTaskListData] = useState<any[]>([]);
    const [FinalArray, setFinalArray] = useState<any[]>([]);
    const [getPmListData, setPmListData] = useState<any[]>([{ PmName: "", _id: "" }]);
    const [open, setOpen] = useState(false);
    const [DataTableShow, setDataTable] = useState<boolean>(false)
    const [DataForValidate, setDataForValidate] = useState<any>([]);
    const [RadioChecked, setRadioChecked] = useState<{ StatusActive: boolean; StatusInactive: boolean }>({
        StatusActive: false,
        StatusInactive: false
    });

    const [CurrentSelectedData, setCurrentSelectedData] = useState<any>({
        UserProductionId : "",
        UserName: "",
        FromDate: "",
        UserId: "",
        RoleName: "",
        ApprovalStatus : "Pending",
        PracticeId  : "",
        WorkingDate : "",
        Comments :"",
    });
    const [formData, setFormData] = useState<any>({
        WorkingStatus: "",
        FromDate: "",
    });
    const [DayWiseProductionUsersCount, setDayWiseProductionUsersCount] = useState<Event[]>([])

    const [FinalProviderArray, setFinalProviderArray] = useState<any[]>([]);

    useEffect(() => {
        const GetPmList = async () => {
            const getPmListData = await axios.get(getPmList);
            if (getPmListData.data) {
                setPmListData(getPmListData.data.data);
            }
        };


        GetPmList();
    }, []);

    useEffect(() => {
        ProdCountByRmFunction();
    }, []);
    const ProdCountByRmFunction = async () => {
        const ProdCountByRmData = await axios.post(ProdCountByRm);
        if (ProdCountByRmData.data.Data) {
            console.log("DayWiseProductionUsersCount", ProdCountByRmData.data.Data)
            setDayWiseProductionUsersCount(ProdCountByRmData.data.Data);
        }
    };
    const getPMSystem = (event: SingleValue<{ label: string; value: string }>) => {
        setSelectedPmSystem(event);
        if (event && event.value) {
            axios.post(getPracticeList, { PostData: event.value }).then((res) => {
                if (res.data.data) {
                    const data = res.data.data;
                    var PracticeData = [{ label: 'Select', value: '' }];
                    data.map((GetRes: any, ind: number) => {
                        PracticeData.push({ label: GetRes.label, value: GetRes._id });
                    });
                    setPracticeListData(PracticeData);
                }
            }).catch((e) => {
                console.log(e);
            });
        } else {
            setSelectedPractice({ label: 'Select', value: '' });
            setPracticeListData([]);
        }
    };
    const getPractice = (event: SingleValue<{ label: string; value: string }>) => {
        if (event && event.value) {
            setSelectedPractice(event);
            setOpen(true);
            axios.get(GetTaskList, {
                params: {
                    PracticeId: event.value
                }
            }).then((res) => {
                setOpen(false);
                setTaskListData([]);
                var Data = [{ label: 'All', value: 'All', Target: "", TargetUnit: "", ProofNeed: "", ProcessName: "" }];
                res.data.Data.map((GetRes: any, ind: number) => {
                    Data.push({
                        label: GetRes.ProcessName,
                        value: GetRes.Task_id,
                        Target: GetRes.Target,
                        TargetUnit: GetRes.TargetUnit,
                        ProofNeed: GetRes.ProofNeed,
                        ProcessName: GetRes.ProcessName,
                    });
                });
                setFinalProviderArray(res.data.Data);
                setTaskListData(Data);
            });
        } else {
            setSelectedPractice({ label: 'Select', value: '' });
        }
    };
    const handleInputChange = async (event: any) => {
        setRadioChecked({ StatusActive: false, StatusInactive: false });
        const { name, value } = event.target;
        var PMName: any = SelectedPmSystem;
        var GetPracticeName: any = SelectedPractice;

        var formDataPost = {
            "PmId": PMName.value,
            "PracticeId": GetPracticeName.value,
            "Date": value
        };
        setFormData((prevState: any) => {
            return {
                ...prevState,
                [name]: value
            }
        });
        setOpen(true);
        const response = await axios.post(GerUsersProduction, formDataPost)
        setOpen(false);
        if (response.data.Data.length > 0) {
            if (response.data.Data[0].WorkingStatus === "Yes") {
                await UpdateUsersProduction(value)
                setRadioChecked({ StatusActive: true, StatusInactive: false });
            } else {
                setRadioChecked({ StatusActive: false, StatusInactive: true });
            }

            if (response.data.Data[0].WorkingTaskList.length > 0) {
                setFinalArray(response.data.Data[0].WorkingTaskList)
                setUsersPracticeProduction((prevState: any) => {
                    return {
                        ...prevState,
                        OthersProduction: response.data.Data[0].WorkingTaskList[0].OthersProduction,
                        FilePath: response.data.Data[0].FilePath,
                        AttachmentName: response.data.Data[0].AttachmentName
                    }
                });
                setFormData((prevState: any) => {
                    return { ...prevState, WorkingStatus: response.data.Data[0].WorkingStatus }
                });
                var ListOfIDs: any = []
                response.data.Data[0].WorkingTaskList.map((GetTaskIds: any, idd: any) => {
                    ListOfIDs.push(GetTaskIds.TaskId)
                })
                var event: any = TaskListData.filter((x: any) => ListOfIDs.includes(x.value))
                console.log("TaskListData", event)
                setSelectedTaskList(event)
            }
        }

    };
    async function UpdateUsersProduction(value: String) {
        if (value) {
            var GetPracticeName: any = SelectedPractice;
            var Count = ""
            var PCA = ""
            var Efficiency = ""
            var PCAEfficiency = ""
            if (currentUser.RoleType == "AR-Caller") {
                setOpen(true);
                var Result = await axios.get(`${ConsolidatedProductivity}?UsersList=${JSON.stringify([{ label: currentUser._id, value: currentUser._id }])}&type=view&StartDate=${value}&ToDate=${value}&page=${0}&limit=${100}&filter=${JSON.stringify([])}&sort=${JSON.stringify([])}`)
                setOpen(false);
                setUsersProductionData(Result.data.data)
                if (Result.data.data.length > 0) {
                    var GetProduction = Result.data.data[0].SubData.filter((x: any) => x.practice_names == GetPracticeName?.label)

                    if (GetProduction.length > 0) {
                        Count = GetProduction[0].Total
                        Efficiency = GetProduction[0].Efficiency
                    }
                }
                setUsersPracticeProduction((prevState: any) => {
                    return {
                        ...prevState,
                        TotalCount: Result.data.data[0].TotalCalled,
                        TotalEfficiency: Result.data.data[0].Efficiency,
                        Count: Count,
                        Efficiency: Efficiency,
                    }
                });

            } else {
                setOpen(true);
                var Result = await axios.get(`${DownlaodExcelAnalystWiseProduction}?UsersList=${JSON.stringify([{ label: currentUser._id, value: currentUser._id }])}&type=view&StartDate=${formData.FromDate}&ToDate=${formData.FromDate}&page=${0}&limit=${100}&filter=${JSON.stringify([])}&sort=${JSON.stringify([])}`)
                setOpen(false);
                console.log("Result", Result)
                setUsersProductionData(Result.data.data)


                if (Result.data.data.length > 0) {
                    var GetProduction = Result.data.data[0].SubData.filter((x: any) => x.practice_names == GetPracticeName?.label)

                    if (GetProduction.length > 0) {
                        Count = GetProduction[0].Total
                        Efficiency = GetProduction[0].Efficiency
                        PCA = GetProduction[0].PCA ? GetProduction[0].PCA : 0
                        PCAEfficiency = GetProduction[0].PCAPer ? GetProduction[0].PCAPer : 0
                    }
                    setUsersPracticeProduction((prevState: any) => {
                        return {
                            ...prevState,
                            TotalCount: Result.data.data[0].TotalCalled,
                            TotalEfficiency: Result.data.data[0].Efficiency,
                            FinalPCACount: Result.data.data[0].FinalPCACount,
                            Count: Count,
                            PCA: PCA,
                            Efficiency: Efficiency,
                        }
                    });
                }
            }
        }
    }
    const handleWorkingStatus = async (e: React.ChangeEvent<HTMLInputElement>) => {

        if (e.target.value === 'Yes') {
            await UpdateUsersProduction(formData.FromDate)
            setRadioChecked({ StatusActive: true, StatusInactive: false });
        } else if (e.target.value === 'No') {
            setRadioChecked({ StatusActive: false, StatusInactive: true });
            setSelectedTaskList([]);
            setFinalArray([]);

        }
        setFormData((prevState: any) => {
            return { ...prevState, WorkingStatus: e.target.value }
        });
    };

    const OnChangeTask = (event: any) => {

        let AllSelect: String = "";
        event.map((GetAll: any, inde: any) => {
            if (GetAll.value == "All") {
                AllSelect = "Yes"
            }
        })
        if (AllSelect == "Yes") {
            event = TaskListData.filter((x: any) => x.value !== "All")
        }
        setSelectedTaskList(event)
        let sum1: number = 0;
        let GetProductionTotal: Array<number> = [];
        var updatedArray: any = [];
        // if(FinalArray.length  == 0){
        //     setFinalArray(event)
        // }else {
        event.map((GetEvent: any, inds: number) => {
            var GetMatchingids = FinalArray.filter((x: any) => x.ProcessName == GetEvent.ProcessName)
            if (GetMatchingids.length > 0) {
                if (GetMatchingids[0].WorkingHours) {
                    let value: number;
                    if (GetMatchingids[0].TargetUnit === "Nos") {
                        value = (GetMatchingids[0].WorkingHours / GetMatchingids[0].Target) * 100;
                    } else {
                        value = (100 / 480) * GetMatchingids[0].WorkingHours;
                    }
                    GetProductionTotal.push(Number(value.toFixed(2)));
                }
                updatedArray.push({ ...GetEvent, ...GetMatchingids[0] })
            } else {
                GetProductionTotal.push(0);
                updatedArray.push({
                    ...GetEvent, ...{
                        WorkingHours: "",
                        WorkingHoursTextError: "",
                        FileNameTextError: "",
                        FileName: ""
                    }
                })
            }
        })
        // FinalArray.map((Prod: ProdValues , ind: number) => {
        //     var GetMatchingids = event.filter((x:any)=> x.ProcessName == Prod.ProcessName)
        //     console.log("GetMatchingids",GetMatchingids)
        //     if(GetMatchingids.length > 0){
        //         if (Prod.WorkingHours) {
        //             let value: number;
        //             if (Prod.TargetUnit === "Nos") {
        //                 value = (Prod.WorkingHours / Prod.Target) * 100;
        //             } else {
        //                 value = (100 / 480) * Prod.WorkingHours;
        //             }
        //             GetProductionTotal.push(Number(value.toFixed(2)));
        //         }
        //         updatedArray.push(Prod)
        //     }
        // });
        setFinalArray(updatedArray)
        // }

        for (let i = 0; i < GetProductionTotal.length; i++) {
            sum1 += GetProductionTotal[i];
        }
        if (GetProductionTotal.length > 0) {
            setUsersPracticeProduction((prevState: any) => {
                return {
                    ...prevState,
                    OthersProduction: Number(sum1.toFixed(2)),
                }
            });
        }
    };
    const DownloadFile = async (e: any) => {
        e.preventDefault();
        setOpen(true)

        await axios.get(e.target.href, { responseType: 'arraybuffer', }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${CurrentSelectedData.AttachmentName}`);
            setOpen(false);
            document.body.appendChild(link);
            link.click();
        }).catch((error) => console.log(error));
        return false;
    }
    const onDrop = useCallback((acceptedFiles: any[]) => {
        var filestotal = acceptedFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }));
        setFiles(filestotal)
    }, []);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'application/zip': ['.zip'],
            'application/x-zip-compressed': ['.zip']
        }
    });

    const acceptedFileItems = acceptedFiles.map((file: any) => {
        return (<li key={file.path}>
            {file.path} - {bytesForHuman(file.size)}
        </li>);
    });

    const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
        var PMName = SelectedPmSystem;
        var GetPracticeName = SelectedPractice;
        if (!PMName || !PMName.value) {
            swal({
                title: '',
                icon: 'info',
                text: 'Please Choose the PM Name'
            });
            return false;
        }
        if (!GetPracticeName || !GetPracticeName.value) {
            swal({
                title: '',
                icon: 'info',
                text: 'Please Choose the Practice Name'
            });
            return false;
        }
        if (!formData.FromDate) {
            swal({
                title: '',
                icon: 'info',
                text: 'Please Choose Date'
            });
            return false;
        }
        if (!formData.WorkingStatus) {
            swal({
                title: '',
                icon: 'info',
                text: 'Please Choose Working Status'
            });
            return false;
        }
        if (formData.WorkingStatus == "Yes") {
            if (SelectedTaskList.length === 0) {
                swal({
                    title: '',
                    icon: 'info',
                    text: 'Please Add Tasks'
                });
                return false;
            }
        }
        var formDataPost = new FormData();
        formDataPost.append("PmId", PMName.value);
        formDataPost.append("PracticeId", GetPracticeName.value);
        formDataPost.append("Date", formData.FromDate);
        formDataPost.append("WorkingStatus", formData.WorkingStatus);
        formDataPost.append("TotalCount", UsersPracticeProduction.TotalCount);
        formDataPost.append("TotalEfficiency", UsersPracticeProduction.TotalEfficiency);
        formDataPost.append("OthersProduction", UsersPracticeProduction.OthersProduction); // Others Production
        formDataPost.append("FinalArray", FinalArray.length > 0 ? JSON.stringify(FinalArray) : '');

        Object.entries(Files).forEach(([key, value]) => {
            formDataPost.append("ClaimFiles", value);
        });
        setOpen(true);
        const response = await axios.post(SaveProduction, formDataPost)
        setOpen(false);
        setFinalArray([])
        setSelectedTaskList([])

        swal({
            title: '',
            icon: "success",
            text: `${response.data.Response}`,
        }).then((_res: any) => {

        })
    };

    const handleUpdateFileName = (event: React.ChangeEvent<HTMLInputElement>, res: any) => {
        const { value, name, dataset, } = event.target;
        var dataError: any = dataset
        var dataErrorField = dataError.errorfield
        var updatedArray: any = [];
        SelectedTaskList.map((item: any, index) => {
            if (item.value === res.value) {
                const ErrorList: { [key: string]: string } = {
                    [dataErrorField]: value ? "" : dataError.error
                };
                Object.assign(item, {
                    [name]: value,
                    ...ErrorList
                });
            }
            updatedArray.push(item)
            if (index == SelectedTaskList.length - 1) {
                setFinalArray(updatedArray);
            }
        });
    };

    const handleUpdateProduction = (event: React.ChangeEvent<HTMLInputElement>, res: any) => {
        const { value, name, dataset, } = event.target;
        var dataError: any = dataset
        var dataErrorField = dataError.errorfield
        var updatedArray: any = [];
        var GetPracticeName = SelectedPractice;
        var Count = ""
        var PCA = ""
        var Efficiency = ""
        var PCAEfficiency = ""

        if (UsersProductionData.length > 0) {
            var GetProductionData: any = UsersProductionData
            var GetProduction = GetProductionData[0].SubData.filter((x: any) => x.practice_names == GetPracticeName?.label)

            if (GetProduction.length > 0) {
                Count = GetProduction[0].Total
                Efficiency = GetProduction[0].Efficiency
                PCA = GetProduction[0].PCA ? GetProduction[0].PCA : 0
                PCAEfficiency = GetProduction[0].PCAPer ? GetProduction[0].PCAPer : 0
            }
        }
        if (name == "WorkingHours") {
            SelectedTaskList.map((item: any, index) => {
                if (item.ProcessName === res.ProcessName) {
                    const ErrorList: { [key: string]: string } = {
                        [dataErrorField]: value ? "" : dataError.error
                    };
                    Object.assign(item, {
                        [name]: value,
                        Count,
                        PCA,
                        Efficiency,
                        PCAEfficiency,
                        ...ErrorList
                    });
                } else {
                    var GetMatchingids = FinalArray.filter((x: any) => x.ProcessName == item.ProcessName)
                    if (GetMatchingids.length > 0) {
                        if (res.ProcessName !== GetMatchingids[0].ProcessName) {
                            if (GetMatchingids[0].WorkingHours) {
                                Object.assign(item, {
                                    WorkingHours: GetMatchingids[0].WorkingHours,
                                    FileName: GetMatchingids[0].FileName,
                                    FileNameTextError: GetMatchingids[0].FileNameTextError,
                                    WorkingHoursTextError: GetMatchingids[0].WorkingHoursTextError,
                                    Count,
                                    PCA,
                                    Efficiency,
                                    PCAEfficiency,
                                });
                            }
                        }
                    }
                }
                updatedArray.push(item)
                if (index == SelectedTaskList.length - 1) {

                    let GetProductionTotal: Array<number> = [];
                    updatedArray.map((Prod: { WorkingHours: number; TargetUnit: string; Target: number }, ind: number) => {
                        if (Prod.WorkingHours) {
                            let value: number;
                            if (Prod.TargetUnit === "Nos") {
                                value = (Prod.WorkingHours / Prod.Target) * 100;
                            } else {
                                value = (100 / 480) * Prod.WorkingHours;
                            }
                            GetProductionTotal.push(Number(value.toFixed(2)));
                        }
                    });

                    let sum1: number = 0;
                    for (let i = 0; i < GetProductionTotal.length; i++) {
                        sum1 += GetProductionTotal[i];
                    }
                    //if (Number(sum1.toFixed(2)) <= 100) {
                        setUsersPracticeProduction((prevState: any) => {
                            return {
                                ...prevState,
                                OthersProduction: Number(sum1.toFixed(2)),
                                Count,
                                PCA,
                                Efficiency,
                                PCAEfficiency,
                            }
                        });
                    //}
                    setFinalArray(updatedArray);
                }
            });
        } else {
            SelectedTaskList.map((item: any, index) => {
                if (item.ProcessName === res.ProcessName) {
                    const ErrorList: { [key: string]: string } = {
                        [dataErrorField]: value ? "" : dataError.error
                    };
                    Object.assign(item, {
                        [name]: value,
                        Count,
                        PCA,
                        Efficiency,
                        PCAEfficiency,
                        ...ErrorList
                    });
                } else {
                    var GetMatchingids = FinalArray.filter((x: any) => x.ProcessName == item.ProcessName)
                    if (GetMatchingids.length > 0) {
                        if (res.ProcessName !== GetMatchingids[0].ProcessName) {
                            Object.assign(item, {
                                WorkingHours: GetMatchingids[0].WorkingHours,
                                FileName: GetMatchingids[0].FileName,
                                FileNameTextError: GetMatchingids[0].FileNameTextError,
                                WorkingHoursTextError: GetMatchingids[0].WorkingHoursTextError,
                                Count,
                                PCA,
                                Efficiency,
                                PCAEfficiency,
                            });
                        }
                    }
                }
                updatedArray.push(item)
                if (index == SelectedTaskList.length - 1) {
                    setFinalArray(updatedArray);
                }
            });
        }

    };
    const handleDateSelect = (selectInfo: DateSelectArg) => {
        let title = prompt('Please enter a new title for your event')
        let calendarApi = selectInfo.view.calendar

        calendarApi.unselect() // clear date selection

        if (title) {
            calendarApi.addEvent({
                id: "1",
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            })
        }
    }
    const formatDate = (dateString: Date, format = "MMMM D, YYYY") => {
        const date = new Date(dateString);

        switch (format) {
            case "YYYY-MM-DD":
                return date.toISOString().split("T")[0];
            case "MMMM D, YYYY":
                return date.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
            case "MM/DD/YYYY":
                return date.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                });
            case "DD-MM-YYYY":
                return date.toISOString().split("T")[0];
            case "YYYY_MM_DD":
                return date.toISOString().split("T")[0];
            default:
                return date.toLocaleDateString();
        }
    };
      
    const handleEventClick = async (clickInfo: EventClickArg) => {
        //console.log("clickInfo", clickInfo)
        const eventTitle = clickInfo.event.title;
        const eventStart : any = clickInfo.event.start;
        const eventEnd = clickInfo.event.end;
        const id = clickInfo.event.id;
        const allDay = clickInfo.event.allDay;
        const clickDate = clickInfo.jsEvent;
        setOpen(true);
        var formDataPost = {
            UserId: id,
            Date: formatDate(eventStart,"YYYY-MM-DD")
        }
        setDataTable(false)
        const response = await axios.post(GerUsersProductionByUserid, formDataPost)
        //console.log("response", response.data)
        if(response.data.Data.length > 0){
            setCurrentSelectedData({
                ApprovalStatus : response.data.Data[0].ApprovalStatus,
                RoleName: response.data.Data[0].RoleName,
                UserName: eventTitle,
                FromDate: formatDate(eventStart,"DD-MM-YYYY"),
                UserId: id,
                UserProductionId : response.data.Data[0].UserProductionId,
                PracticeId  : response.data.Data[0]._id[0],
                WorkingDate : formatDate(eventStart,"DD-MM-YYYY"),
                AttachmentName : response.data.Data[0].AttachmentName,
                Comments : response.data.Data[0]?.Comments || null,
            })
            setDataForValidate(response.data.Data)
            setOpen(false);
            setDataTable(true)
        }else {
            setOpen(false);
            swal({
                title: '',
                icon: "success",
                text: `${response.data.Data.Message}`,
            }).then((_res: any) => {
    
            })
            setDataTable(false)
        }
        
    }
    const CloseModal = (_event: any) => {
        setDataTable(false)
    }
    const handleEvents = (events: EventApi[]) => {
        
        console.log("events", events)
    }
    function renderEventContent(eventContent: EventContentArg) {
        return (
            <>
                {/* <b>{eventContent.timeText}</b>  */}
                <i className={eventContent.event.textColor} >{eventContent.event.title}</i>
            </>
        )
    }
    const Approve = (UserID: String, WorkingDate: Date,UserProductionId:String) => {
        setOpen(true)
        axios.post(TaskApproveByUser, { UserID,WorkingDate,UserProductionId }).then((res) => {
            if (res.data.Message) {
                setOpen(false)
                setCurrentSelectedData((prevState: any) => {
                    return {
                        ...prevState,
                        ApprovalStatus: "Approved",
                        Comments : ""
                    }
                });
            }
        }).catch((e) => {
            console.log(e);
        });
    };
    const Reject = (UserID: String, WorkingDate: Date, UserProductionId: String,) => {

        const textarea :any = document.createElement("textarea");
        textarea.placeholder = "Enter reason here...";
        textarea.rows = 4;
        textarea.style.width = "100%"; // Ensure full width in the alert box
        swal({
            title: "Reject Request",
            text: "Enter your reason for rejection:",
            content: textarea,
            buttons: ["Cancel", "Reject"],
            dangerMode: true,
        }).then((confirmed:any) => {
            if (!confirmed) return; // If cancel is clicked, exit

            const reason = textarea.value.trim();
            if (!reason) {
                swal("Error", "Rejection reason cannot be empty!", "error");
                return;
            }
            const payload = {
                ApprovalStatus: "Rejected",
                UserId: UserID,
                WorkingDate: WorkingDate,
                UserProductionId: UserProductionId,
                Comments: reason,
            };

            axios.post(TaskApproveByUser, payload).then((res) => {
                if (res.data.Message) {
                    setOpen(false)
                    setCurrentSelectedData((prevState: any) => {
                        return {
                            ...prevState,
                            ApprovalStatus: "Rejected",
                            Comments: ""
                        }
                    });
                }
            }).catch((e) => {
                console.log(e);
            });

        });
    }
    
    const handleReject = (UserID: String, WorkingDate: Date,UserProductionId:String,) => {
        setOpen(true)
        axios.post(TaskApproveByUser, { UserID,WorkingDate,UserProductionId }).then((res) => {
            if (res.data.Message) {
                setOpen(false)
                setCurrentSelectedData((prevState: any) => {
                    return {
                        ...prevState,
                        ApprovalStatus: "Rejected",
                        Comments : ""
                    }
                });
            }
        }).catch((e) => {
            console.log(e);
        });
    };
    return (
        <>
            {open === true && (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color='inherit' />
                </Backdrop>
            )}
            <div className='card'>
                <div className='card-body'>
                    <div className='row mb-5'>

                        <div className='form-group col-lg-12'>
                            <div id="calendar">
                                {DayWiseProductionUsersCount.length > 0 ?
                                    <FullCalendar
                                        //height={"auto"}
                                        //contentHeight={"auto"}
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                        }}
                                        initialView='dayGridMonth'
                                        editable={true}
                                        selectable={true}
                                        selectMirror={true}
                                        dayMaxEvents={true}
                                        initialEvents={DayWiseProductionUsersCount}
                                        //select={handleDateSelect}
                                        eventContent={renderEventContent} // custom render function
                                        eventClick={handleEventClick}
                                        eventsSet={handleEvents}
                                        timeZone="Asia/Kolkata"
                                    /> : null
                                }

                            </div>
                        </div>

                    </div>

                    {/* <div className='row mb-5'>
                        <div className='form-group col-lg-4'>
                            <button type='submit' className='btn btn-primary' >
                                Submit
                            </button>
                            

                        </div>

                    </div> */}
                </div>

            </div>
            <div className={`modal fade ${DataTableShow ? "show d-block" : null}`} id='kt_modal_add_user' role='dialog' tabIndex={-1} aria-modal='false'>
                <div className='modal-dialog modal-dialog-scrollable modal-fullscreen'>
                    <div className='modal-content' style={{ backgroundColor: "#e1e1e1" }}>
                        <div className='modal-header d-flex justify-content-between align-items-center'>
                        <span>
                            <b>User Name:</b> {CurrentSelectedData.UserName} &nbsp; 
                            <b>Date:</b> {CurrentSelectedData.WorkingDate}
                            {CurrentSelectedData.Comments && (
                                <>
                                &nbsp; <b>Rejected Comments:</b> {CurrentSelectedData.Comments}
                                </>
                            )}
                            </span>
                            <div className='d-flex align-items-center'>
                                {CurrentSelectedData.AttachmentName ? 
                                    <a  onClick={(e) => { DownloadFile(e) }} href={`/api/ProductionTrack/DownloadFile?UserId=${CurrentSelectedData.UserId}&WorkingDate=${CurrentSelectedData.WorkingDate.replace(/-/g, "_")}&PracticeId=${CurrentSelectedData.PracticeId}&FileName=${CurrentSelectedData.AttachmentName}`} className='btn btn-primary m-1'>  Dowload File</a> : null }

                                

                                <Button disabled={CurrentSelectedData.ApprovalStatus === "Approve" ? false : true} variant='primary' onClick={() => Approve(CurrentSelectedData.UserId, CurrentSelectedData.FromDate,CurrentSelectedData.UserProductionId)}>
                                    {CurrentSelectedData.ApprovalStatus}
                                </Button>
                                {
                                    CurrentSelectedData.ApprovalStatus == "Approve" ?
                                    <Button  className='ms-3' variant='info' onClick={() => Reject(CurrentSelectedData.UserId, CurrentSelectedData.FromDate,CurrentSelectedData.UserProductionId)}>
                                    Reject
                                </Button> : null
                                }
                                <div
                                    onClick={CloseModal}
                                    className='btn btn-icon btn-danger btn-sm ms-3'
                                    data-kt-users-modal-action='close'
                                    style={{ cursor: 'pointer' }}
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                </div>
                            </div>
                        </div>
                        <div className='modal-body scroll-y'>
                            <DataTable data={DataForValidate} RoleName={CurrentSelectedData.RoleName} UserID={CurrentSelectedData.UserId} WorkingDate={CurrentSelectedData.FromDate} />
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
};

export default ValidateProduction;

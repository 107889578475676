import React, { useState, useEffect } from 'react'
import { UserDetails, UserDetailsDetails as initialValues } from '../SampleTemplate/models/template'
import axios from 'axios'
import Select from 'react-select';
import swal from "sweetalert"

const pmsystemUrl = `/api/roles/getRoleList`
const getRoleList = `/api/roles/getRoleList`


interface CreateUserButtonProps {
    userId: string; // Receive the userId prop
    getUpdatedUser: any,
    isUpdateMode: boolean,
}
export const REGISTER_URL = `/users/CreateUser`
export function createUsers(updatedData: object) {
    return axios.post(REGISTER_URL, updatedData)
}

const CreateUserButton: React.FC<CreateUserButtonProps> = ({ isUpdateMode,userId, getUpdatedUser }) => {

    const [UserName, setUserName] = useState('')
    const [FirstName, setFirstName] = useState('')
    const [Email, setEmail] = useState('')
    const [phone_number, setphone_number] = useState('')

    const [data, setData] = useState<UserDetails>(initialValues)
    const [SelectedPmRole, setSelectedPmRole] = useState<{ label: string; value: string }>({ label: "", value: "" })
    const [SelectedRole, setSelectedRole] = useState({ label: "", value: "" })
    const [PracticeListData, setPracticeListData] = useState([])
    const [getPmListData, setdatass] = useState<Array<{ role_name: string, _id: string }>>([])


    console.log("userId", userId);


    const [errors, setErrors] = useState({
        UserName: '',
        FirstName: '',
        lableMiddleName: '',
        labelGivenName: '',
        Email: '',
        phone_number: '',
        pmSystem: '',
        practiceName: ''
    })


    useEffect(() => {
        if (userId) {
            axios.get(`/api/createuser/users?user_id=${userId}`)
                .then(async (response) => {
                    const userData = response.data.data;

                    setUserName(userData.LastName || '');
                    setFirstName(userData.FirstName || '');
                    setEmail(userData.EmailId || '');
                    setphone_number(userData.Contact || '');

                    if (userData.RoleName || userData.RoleNamePm) {

                        try {
                            const roleResponse = userData.RoleName
                                ? await axios.get(`/api/roles/roles?role_id=${userData.RoleName}`)
                                : null;

                            const pmroleResponse = userData.RoleNamePm
                                ? await axios.get(`/api/roles/roles?role_id=${userData.RoleNamePm}`)
                                : null;

                            const roleData = roleResponse ? roleResponse.data.data : null;
                            const pmroleData = pmroleResponse ? pmroleResponse.data.data : null;

                            if (roleData && pmroleData) {
                                setSelectedRole({ label: roleData.role_name, value: roleData._id });
                                setSelectedPmRole({ label: pmroleData.role_name, value: pmroleData._id });
                            } else if (roleData) {
                                setSelectedRole({ label: roleData.role_name, value: roleData._id });
                            } else if (pmroleData) {
                                setSelectedPmRole({ label: pmroleData.role_name, value: pmroleData._id });
                            }

                        } catch (error) {
                            console.error("Error fetching role data:", error);
                        }
                    }
                })
                .catch(error => {
                    console.error("Error fetching user data:", error);
                });
        }
    }, [userId]);



    useEffect(() => {
        GetPmList()
    }, [])


    const GetPmList = async () => {
        try {
            const getPmListData = await axios.get(pmsystemUrl);
            if (getPmListData.data) {
                setdatass(getPmListData.data.data);
            }
        } catch (error) {
            console.error("Error fetching PM list:", error);
        }
    }

    const getRole = (event: any) => {
        if (Object.keys(event).length > 0) {
            setSelectedRole(event)
        } else {
            setSelectedRole({ label: "", value: "" })
        }
    }
    const getPMSystem = (event: any) => {
        setSelectedPmRole(event)
        if (event.value) {
            axios.post(getRoleList, { PostData: event.value }).then((res) => {
                setPracticeListData(res.data.data)
            })
        }
    };
    const PmListData = [{ label: "Select", value: "" }];
    if (getPmListData && getPmListData.length > 0) {
        getPmListData.map((name) => {
            if (name.role_name.startsWith("PM-")) {
                PmListData.push({
                    label: name.role_name,
                    value: name._id,
                });
            }
        });
    }
    const NotPmListData = [{ label: "Select", value: "" }];
    if (getPmListData && getPmListData.length > 0) {
        getPmListData.map((name) => {
            if (!name.role_name.startsWith("PM-")) {
                NotPmListData.push({
                    label: name.role_name,
                    value: name._id,
                });
            }
        });
    }
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {
            UserName: '',
            labelGivenName: '',
            FirstName: '',
            lableMiddleName: '',
            Email: '',
            phone_number: '',
            pmSystem: '',
            practiceName: ''
        };
        if (!UserName) {
            newErrors.UserName = 'User Name is required';
            formIsValid = false;
        }
        if (!FirstName) {
            newErrors.FirstName = 'Preferred Name is required';
            formIsValid = false;
        }

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!Email || !emailRegex.test(Email)) {
            newErrors.Email = 'Valid Email is required';
            formIsValid = false;
        }
        const phoneRegex = /^[\d+\-()\s]*$/;
        if (!phone_number || !phoneRegex.test(phone_number)) {
            newErrors.phone_number = 'Valid Mobile Number is required';
            formIsValid = false;
        }
        if (!SelectedPmRole || !SelectedPmRole.value) {
            newErrors.pmSystem = 'PM Role is required';
            formIsValid = false;
        }
        if (!SelectedRole || !SelectedRole.value) {
            newErrors.practiceName = 'Role Name is required';
            formIsValid = false;
        }
        setErrors(newErrors);
        return formIsValid;
    }
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (validateForm()) {
            console.log('Form is valid. Proceeding with submission.');
        }

        var PostData = {
            UserName: UserName,
            PreferredUserName: FirstName,
            MobileNumber: phone_number,
            Email: Email,
            GroupName: SelectedRole.value,
            GroupNamePM: SelectedPmRole.value

        }
        const data = await createUsers(PostData)

        setUserName('');
        setFirstName( '');
        setEmail('');
        setphone_number('');

        swal({
            title: 'Success',
            icon: "success",
            text: `User ${isUpdateMode ? `Update` : `Create`} Successfully ... !`,
        }).then((_res: any) => {

        })
        getUpdatedUser(data)
    }

    return (
        <>
            <div className='card mb-5 mb-xl-10'>
                <div className='card-header border-0 cursor-pointer' role='button' data-bs-toggle='collapse' data-bs-target='#kt_account_profile_details' aria-expanded='true' aria-controls='kt_account_profile_details'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>{isUpdateMode ? 'Update User' : 'Create User'}</h3>
                    </div>
                </div>

                <div id='kt_account_profile_details' className='collapse show'>
                    <div className='card-body border-top p-8'>
                        <form onSubmit={handleSubmit}>
                            {/* First row with 4 fields */}
                            <div className='form-group row'>
                                <div className="col-sm-3 fv-row">
                                    <label className='col-lg-8 col-form-label fw-bold fs-6 required' htmlFor="Label">User Name*</label>
                                    <input style={{ height: '38px' }} className="form-control" id="Name" value={UserName} name={'Name'} onChange={(e) => { setUserName(e.target.value) }} />
                                    {errors.UserName && <span className="text-danger">{errors.UserName}</span>}
                                </div>

                                <div className="col-sm-3 fv-row">
                                    <label className='col-lg-12 col-form-label fw-bold fs-6 required' htmlFor="Label">Preferred Name*</label>
                                    <input style={{ height: '38px' }} className="form-control" id="Label" value={FirstName} name='label' onChange={(e) => { setFirstName(e.target.value) }} />
                                    {errors.FirstName && <span className="text-danger">{errors.FirstName}</span>}
                                </div>


                                <div className="col-sm-3 fv-row">
                                    <label className='col-lg-8 col-form-label fw-bold fs-6 required' htmlFor="Label">Email*</label>
                                    <input style={{ height: '38px' }} className="form-control" id="Label" value={Email} name='label' onChange={(e) => { setEmail(e.target.value) }} />
                                    {errors.Email && <span className="text-danger">{errors.Email}</span>}
                                </div>
                                <div className="col-sm-3 fv-row">
                                    <label className='col-lg-12 col-form-label fw-bold fs-6 required' htmlFor="Label">Phone No*</label>
                                    <input style={{ height: '38px' }} className="form-control" id="Label" value={phone_number} name='label' onChange={(e) => { setphone_number(e.target.value) }} />
                                    {errors.phone_number && <span className="text-danger">{errors.phone_number}</span>}
                                </div>
                                <div className="col-sm-3 fv-row">
                                    <label className='col-lg-18 col-form-label fw-bold fs-6'>
                                        <span className='required'>PM Role Name</span>
                                    </label><br />
                                    <Select maxMenuHeight={180} value={SelectedPmRole} onChange={getPMSystem} id="pm_system" name={"pm_system"} options={PmListData} />
                                    {errors.pmSystem && <span className="text-danger">{errors.pmSystem}</span>}
                                </div>
                                <div className="col-sm-3 fv-row">
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                        <span className='required'>Role Name</span>
                                    </label><br />
                                    <Select maxMenuHeight={180} value={SelectedRole} onChange={getRole} id="practice_name" name={"practice_name"} options={NotPmListData} />
                                    {errors.practiceName && <span className="text-danger">{errors.practiceName}</span>}
                                </div>
                            </div>
                            {/* Button Row */}
                            <div className="row mt-12">
                                <div className="col-6 d-flex justify-content-start">
                                    {/* Cancel Button can be added here if needed */}
                                </div>
                                <div className="col-6 d-flex justify-content-end">
                                    <button type="submit" className="btn btn-primary btn-lg" >Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export { CreateUserButton }
